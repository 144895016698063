import React, { useState, useEffect } from 'react';
import logo from '../assets/logos/logo.jpg';
import './css/header.css';
import { Link } from 'react-router-dom';
import useWindowSize from './useWindowSize';

const Header = ({ scrolling }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState({});
    const size = useWindowSize();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleDropdown = (dropdownName) => {
        setDropdownOpen((prevState) => ({
            ...prevState,
            [dropdownName]: !prevState[dropdownName],
        }));
    };

    const isMobile = size.width <= 768;

    // Prevent body scroll when menu is open
    useEffect(() => {
        if (menuOpen) {
            document.body.classList.add('menu-open');
        } else {
            document.body.classList.remove('menu-open');
        }
    }, [menuOpen]);

    return (
        <>
            {isMobile ? (
                <header className="Mobile-header">
                    <a href="/" className="mobile-logo">
                        <img
                            src={logo}
                            alt="Logo"
                            style={{
                                maxHeight: '70px',
                                maxWidth: '100%',
                                height: 'auto',
                                width: 'auto',
                            }}
                        />
                    </a>
                    <button
                        className={`hamburger ${menuOpen ? 'active' : ''}`}
                        onClick={toggleMenu}
                        aria-label="Toggle navigation"
                        aria-expanded={menuOpen}
                        aria-controls="mobile-nav"
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <nav id="mobile-nav" className={`mobile-nav-links ${menuOpen ? 'show' : ''}`}>
                        <ul>
                            <li>
                                <a href="/about">À PROPOS</a>
                            </li>
                            <li
                                className={`has-dropdown ${dropdownOpen['offers'] ? 'open' : ''}`}
                                onClick={() => toggleDropdown('offers')}
                            >
                                <a href="#">
                                    <span
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            window.location.href = '/offers';
                                        }}
                                    >
                                        NOS OFFRES
                                    </span>
                                </a>
                                <ul className="mobile-dropdown-content">
                                    <li><h4>Nos offres actuelles</h4></li>
                                    <li><Link to="/Architecture">Architecture</Link></li>
                                    <li><Link to="/Interiordesign">Design intérieur</Link></li>
                                    <li><Link to="/FengShuiDiagnosis">Diagnostic Feng Shui</Link></li>
                                    <li><Link to="/HomeStagingFengShui">Home Staging Feng Shui</Link></li>
                                    <li><Link to="/EcoLandScapeOnline">Paysage écologique en ligne</Link></li>
                                    <li><Link to="/BioEcoArchitecture">Bio Eco-Architecture</Link></li>
                                </ul>
                            </li>
                            <li>
                                <a href="/how-it-works">COMMENT ÇA MARCHE</a>
                            </li>
                            <li>
                                <a href="/achievements">RÉALISATIONS</a>
                            </li>
                            <li>
                                <a href="/renovations">RÉNOVATIONS</a>
                            </li>
                            <li
                                className={`has-dropdown ${dropdownOpen['art'] ? 'open' : ''}`}
                                onClick={() => toggleDropdown('art')}
                            >
                                <a href="#">
                                    <span
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            window.location.href = '/art';
                                        }}
                                    >
                                        ART
                                    </span>
                                </a>
                                <ul className="mobile-dropdown-content">
                                    <li><h4>Galerie En Ligne</h4></li>
                                    <li><a href="/Art">Art</a></li>
                                    <li><h4>Artistes créatifs</h4></li>
                                    <li><a href="/Sarah ARENSI">Sarah ARENSI</a></li>
                                    <li><a href="/ARMAN">ARMAN</a></li>
                                    <li><Link to="/lanatikhonova">Lana TIKHONOVA</Link></li>
                                    <li><Link to="/NADDAYELLA">NADDAYELLA</Link></li>
                                    <li><a href="/Claire BIETTE">Claire BIETTE</a></li>
                                    <li><a href="/Rose DESMAISONS">Rose DESMAISONS</a></li>
                                    <li><a href="/Nathalie FOSSE">Nathalie FOSSE</a></li>
                                    <li><a href="/Claire BIETTE">Claire BIETTE</a></li>
                                    <li><a href="/Michel GAUTHIER">Michel GAUTHIER</a></li>
                                    <li><a href="/Joseph GHANEM">Joseph GHANEM</a></li>
                                    <li><a href="/Odile JACENKO">Odile JACENKO</a></li>
                                    <li><a href="/Vero REATO">Vero REATO</a></li>
                                    <li><a href="/Guillaume ROCHE">Guillaume ROCHE</a></li>
                                    <li><a href="/Sigvard SCHOU">Sigvard SCHOU</a></li>
                                    <li><a href="/Andrew VICARI">Andrew VICARI</a></li>
                                    <li><a href="/NADDAYELLA">NEVY de COLLANEV</a></li>
                                    <li><h4>Les créations</h4></li>
                                    <li><a href="/Sculptures">Sculptures</a></li>
                                    <li><a href="/Lithographs">Lithographies</a></li>
                                    <li><a href="/Paintings">Peintures</a></li>
                                    <li><h4>Matériaux</h4></li>
                                    <li><a href="/Steel">Acier</a></li>
                                    <li><a href="/Marble">Marbre</a></li>
                                    <li><a href="/Cultured concrete">Béton culturel</a></li>
                                    <li><a href="/Enamel on Lava">Émail sur lave</a></li>
                                    <li><a href="/Acrylic-on-Canvas">Acrylique sur toile</a></li>
                                    <li><a href="/Painting-on-tracing-paper">Peinture sur papier calque</a></li>
                                    <li><a href="/Paint-on-wood">Peinture sur bois</a></li>
                                    <li><a href="/Watercolor">Aquarelle</a></li>
                                    <li><h4>STUDIO NFT</h4></li>
                                    <li><a href="/About NFTs">À propos des NFT</a></li>
                                </ul>
                            </li>
                            <li
                                className={`has-dropdown ${dropdownOpen['shop'] ? 'open' : ''}`}
                                onClick={() => toggleDropdown('shop')}
                            >
                                <a href="#">
                                    <span
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            window.location.href = '/shop';
                                        }}
                                    >
                                        BOUTIQUE
                                    </span>
                                </a>
                                <ul className="mobile-dropdown-content">
                                    <li><h4>BÉTON</h4></li>
                                    <li><a href="/Concrete">Béton</a></li>
                                    <li><a href="/Lime">Chaux</a></li>
                                    <li><a href="/Hybrid-Concrete">Béton hybride</a></li>
                                    <li><h4>Chaux</h4></li>
                                    <li><a href="/Tadelakt">Tadelakt</a></li>
                                    <li><a href="/Fine-and-Medium-Grain-Lime">Chaux fine et moyenne granulométrie</a></li>
                                    <li><a href="/Paintings">Badigeon de chaux</a></li>
                                    <li><h4>Revêtements</h4></li>
                                    <li><a href="/Sénipierres">Sénipierres</a></li>
                                    <li><a href="/Provence-Decors">Décors de Provence</a></li>
                                    <li><a href="/Argile">Argile</a></li>
                                    <li><h4>EFFETS DÉCORATIFS</h4></li>
                                    <li><a href="/Sénipierres">Effet minéral mat</a></li>
                                    <li><a href="/Mineral-Metal-Effect">Effet métal minéral</a></li>
                                    <li><a href="/Crystal">Cristal</a></li>
                                    <li><a href="/Diamonds">Diamants</a></li>
                                    <li><a href="/Cabaret">Cabaret</a></li>
                                    <li><a href="/NewLook">Nouveau look</a></li>
                                    <li><a href="/Anthéa">Anthéa</a></li>
                                    <li><h4>COLORATION</h4></li>
                                    <li><a href="/Colouring-syringes">Seringues de coloration</a></li>
                                    <li><a href="/Pigments">Pigments</a></li>
                                    <li><a href="/Color-Dosage">Dosage de couleur</a></li>
                                    <li><a href="/Matte-Varnish">Vernis mat</a></li>
                                    <li><a href="/Satin-Varnish">Vernis satiné</a></li>
                                    <li><a href="/Wax">Cire</a></li>
                                    <li><h4>MEUBLES</h4></li>
                                    <li><a href="/Sofas">Sofas</a></li>
                                    <li><a href="/Armchairs">Fauteuils</a></li>
                                    <li><a href="/Tables">Tables</a></li>
                                    <li><a href="/Coffee-Tables">Tables basses</a></li>
                                    <li><a href="/Chairs">Chaises</a></li>
                                    <li><a href="/Desks">Bureaux</a></li>
                                    <li><a href="/Side-tables">Tables d'appoint</a></li>
                                    <li><a href="/Beds">Lits</a></li>
                                    <li><a href="/Lighting">Éclairage</a></li>
                                    <li><a href="/Leisure-Games-Sports">Loisirs - Jeux - Sports</a></li>
                                    <li><a href="/Murano">Murano</a></li>
                                    <li><a href="/Consol">Console</a></li>
                                    <li><a href="/Shelves">Étagères</a></li>
                                    <li><h4>SIGNATURES</h4></li>
                                    <li><a href="/Manuel-CANOVAS">Manuel CANOVAS</a></li>
                                    <li><a href="/CASUAL">CASUAL</a></li>
                                    <li><a href="/ESEDRA">ESEDRA</a></li>
                                    <li><h4>CRÉATEURS — DESIGNERS</h4></li>
                                    <li><a href="/ARCHIBUILD-TaniosHAMAM">ARCHIBUILD — Tanios HAMAM</a></li>
                                    <li><a href="/NathalieFOSSE">Nathalie FOSSE</a></li>
                                    <li><a href="/MITHKA-DESIGN-MadeleineHOFFMANN">MITHKA DESIGN — Madeleine HOFFMANN</a></li>
                                    <li><a href="/PÉPITE-DE-LAVE-RoseDESMAISONS">PÉPITE DE LAVE — Rose DESMAISONS</a></li>
                                    <li><a href="/DECORENKO-OdileJACENKO">DECORENKO — Odile JACENKO</a></li>
                                    <li><a href="/OlivierTOULOUSE">Olivier TOULOUSE</a></li>
                                    <li><a href="/FlorenceBOUREL">Florence BOUREL</a></li>
                                    <li><a href="/CyrilGORIN">Cyril GORIN</a></li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </header>
            ) : (
                <header className={`App-header ${scrolling ? 'scrolled' : ''}`}>
                    <div className="logo">
                        <img
                            src={logo}
                            alt="Logo"
                            style={{
                                maxHeight: '70px',
                                maxWidth: '100%',
                                height: 'auto',
                                width: 'auto',
                            }}
                        />
                    </div>
                    <ul className="header-nav-links">
                        <li>
                            <a href="/about">À PROPOS</a>
                        </li>
                        <li className="offers-dropdown">
                            <a href="/offers">NOS OFFRES</a>
                            <div className="dropdown-content offers">
                                <div className="dropdown-section">
                                    <h4>Nos offres actuelles</h4>
                                    <Link to="/Architecture">Architecture</Link>
                                    <Link to="/Interiordesign">Design intérieur</Link>
                                    <Link to="/FengShuiDiagnosis">Diagnostic Feng Shui</Link>
                                    <Link to="/HomeStagingFengShui">Home Staging Feng Shui</Link>
                                    <Link to="/EcoLandScapeOnline">Paysage écologique en ligne</Link>
                                    <Link to="/BioEcoArchitecture">Bio Eco-Architecture</Link>
                                </div>
                            </div>
                        </li>
                        <li>
                            <a href="/how-it-works">COMMENT ÇA MARCHE</a>
                        </li>
                        <li>
                            <a href="/achievements">RÉALISATIONS</a>
                        </li>
                        <li>
                            <a href="/renovations">RÉNOVATIONS</a>
                        </li>
                        <li className="art-dropdown">
                            <Link to="/art">ART</Link>
                            <div className="dropdown-content art">
                                <div className="dropdown-section artists-grid">
                                    <h4>Artistes créatifs</h4>
                                    <div className="artists-links-grid">
                                        <a href="/Sarah ARENSI">Sarah ARENSI</a>
                                        <a href="/ARMAN">ARMAN</a>
                                        <Link to="/lanatikhonova">Lana TIKHONOVA</Link>
                                        <Link to="/NADDAYELLA">NADDAYELLA</Link>
                                        <a href="/Claire BIETTE">Claire BIETTE</a>
                                        <a href="/Rose DESMAISONS">Rose DESMAISONS</a>
                                        <a href="/Nathalie FOSSE">Nathalie FOSSE</a>
                                        <a href="/Claire BIETTE">Claire BIETTE</a>
                                        <a href="/Michel GAUTHIER">Michel GAUTHIER</a>
                                        <a href="/Joseph GHANEM">Joseph GHANEM</a>
                                        <a href="/Odile JACENKO">Odile JACENKO</a>
                                        <a href="/Vero REATO">Vero REATO</a>
                                        <a href="/Guillaume ROCHE">Guillaume ROCHE</a>
                                        <a href="/Sigvard SCHOU">Sigvard SCHOU</a>
                                        <a href="/Andrew VICARI">Andrew VICARI</a>
                                        <a href="/NADDAYELLA">NEVY de COLLANEV</a>
                                    </div>
                                </div>
                                <div className="dropdown-section">
                                    <h4>Les créations</h4>
                                    <a href="/Sculptures">Sculptures</a>
                                    <a href="/Lithographs">Lithographies</a>
                                    <a href="/Paintings">Peintures</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>Matériaux</h4>
                                    <a href="/Steel">Acier</a>
                                    <a href="/Marble">Marbre</a>
                                    <a href="/Cultured concrete">Béton culturel</a>
                                    <a href="/Enamel on Lava">Émail sur lave</a>
                                    <a href="/Acrylic-on-Canvas">Acrylique sur toile</a>
                                    <a href="/Painting-on-tracing-paper">Peinture sur papier calque</a>
                                    <a href="/Paint-on-wood">Peinture sur bois</a>
                                    <a href="/Watercolor">Aquarelle</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>STUDIO NFT</h4>
                                    <a href="/About NFTs">À propos des NFT</a>
                                </div>
                            </div>
                        </li>
                        <li className="shop-dropdown">
                            <a href="/shop">BOUTIQUE</a>
                            <div className="dropdown-content shop">
                                <div className="dropdown-section">
                                    <h4>BÉTON</h4>
                                    <a href="/Concrete">Béton</a>
                                    <a href="/Lime">Chaux</a>
                                    <a href="/Hybrid-Concrete">Béton hybride</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>Chaux</h4>
                                    <a href="/Tadelakt">Tadelakt</a>
                                    <a href="/Fine-and-Medium-Grain-Lime">Chaux fine et moyenne granulométrie</a>
                                    <a href="/Paintings">Badigeon de chaux</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>Revêtements</h4>
                                    <a href="/Sénipierres">Sénipierres</a>
                                    <a href="/Provence-Decors">Décors de Provence</a>
                                    <a href="/Argile">Argile</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>EFFETS DÉCORATIFS</h4>
                                    <a href="/Sénipierres">Effet minéral mat</a>
                                    <a href="/Mineral-Metal-Effect">Effet métal minéral</a>
                                    <a href="/Crystal">Cristal</a>
                                    <a href="/Diamonds">Diamants</a>
                                    <a href="/Cabaret">Cabaret</a>
                                    <a href="/NewLook">Nouveau look</a>
                                    <a href="/Anthéa">Anthéa</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>COLORATION</h4>
                                    <a href="/Colouring-syringes">Seringues de coloration</a>
                                    <a href="/Pigments">Pigments</a>
                                    <a href="/Color-Dosage">Dosage de couleur</a>
                                    <a href="/Matte-Varnish">Vernis mat</a>
                                    <a href="/Satin-Varnish">Vernis satiné</a>
                                    <a href="/Wax">Cire</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>MEUBLES</h4>
                                    <a href="/Sofas">Sofas</a>
                                    <a href="/Armchairs">Fauteuils</a>
                                    <a href="/Tables">Tables</a>
                                    <a href="/Coffee-Tables">Tables basses</a>
                                    <a href="/Chairs">Chaises</a>
                                    <a href="/Desks">Bureaux</a>
                                    <a href="/Side-tables">Tables d'appoint</a>
                                    <a href="/Beds">Lits</a>
                                    <a href="/Lighting">Éclairage</a>
                                    <a href="/Leisure-Games-Sports">Loisirs - Jeux - Sports</a>
                                    <a href="/Murano">Murano</a>
                                    <a href="/Consol">Console</a>
                                    <a href="/Shelves">Étagères</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>SIGNATURES</h4>
                                    <a href="/Manuel-CANOVAS">Manuel CANOVAS</a>
                                    <a href="/CASUAL">CASUAL</a>
                                    <a href="/ESEDRA">ESEDRA</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>CRÉATEURS — DESIGNERS</h4>
                                    <a href="/ARCHIBUILD-TaniosHAMAM">ARCHIBUILD — Tanios HAMAM</a>
                                    <a href="/NathalieFOSSE">Nathalie FOSSE</a>
                                    <a href="/MITHKA-DESIGN-MadeleineHOFFMANN">MITHKA DESIGN — Madeleine HOFFMANN</a>
                                    <a href="/PÉPITE-DE-LAVE-RoseDESMAISONS">PÉPITE DE LAVE — Rose DESMAISONS</a>
                                    <a href="/DECORENKO-OdileJACENKO">DECORENKO — Odile JACENKO</a>
                                    <a href="/OlivierTOULOUSE">Olivier TOULOUSE</a>
                                    <a href="/FlorenceBOUREL">Florence BOUREL</a>
                                    <a href="/CyrilGORIN">Cyril GORIN</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </header>
            )}
        </>
    );
};

export default Header;
