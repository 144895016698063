import React, { useState, useEffect, useCallback, useRef } from 'react';
import Header from '../frontpage/header';
import './css/fengshuidiagnosis.css';
import img1 from './assets/FengshuiDiagnosis/img1.jpg';
import img2 from './assets/FengshuiDiagnosis/img2.jpg';
import exampleImage from './assets/FengshuiDiagnosis/example.jpg';

const carouselItems = [
  {
    id: 1,
    leftTitle: "Un premier pas, un premier regard",
    leftText: "La première approche pour décoder un lieu à distance.",
    rightTitle: "Expertises en Feng Shui",
    rightText: "Une évaluation Feng Shui se déroule en 4 étapes : audit, évaluation, recommandations et suivi.",
    imageUrl: img1,
  },
  {
    id: 2,
    leftTitle: "L'Harmonie Commence à la Maison : Adoptez le Feng Shui pour un Foyer Aimant",
    leftText: "Aimez votre maison et elle vous le rendra. C'est du bon feng shui.",
    rightTitle: "IDENTIFIER - CAPTURER - FORMER",
    rightText: "L'expertise en Feng Shui vise à rétablir une qualité énergétique réelle dans les espaces de vie. Un environnement harmonieux est créé dans le but d'améliorer l'équilibre personnel et/ou professionnel ainsi que la vie quotidienne. En termes concrets, cette science apporte du bien-être et aide à se protéger contre les énergies néfastes. Elle nous permet de capturer et d'exploiter tout ce qui est favorable, et offre des solutions durables.",
    rightText2: "CONNAÎTRE - AMÉLIORER - PRÉDIRE",
    imageUrl: img2,
  },
];

const FengShuiDiagnosis = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [textKey, setTextKey] = useState(0);
  const [imageKey, setImageKey] = useState(0);
  const intervalRef = useRef();

  const startSlideShow = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      setTextKey(prevKey => prevKey + 1);
      setImageKey(prevKey => prevKey + 1);
      setCurrentSlide((prevSlide) => (prevSlide + 1) % carouselItems.length);
    }, 10000);
  }, []);

  useEffect(() => {
    startSlideShow();
    return () => clearInterval(intervalRef.current);
  }, [startSlideShow]);

  const goToSlide = useCallback((index) => {
    if (index !== currentSlide) {
      setCurrentSlide(index);
      setTextKey(prevKey => prevKey + 1);
      setImageKey(prevKey => prevKey + 1);
      startSlideShow();
    }
  }, [currentSlide, startSlideShow]);

  return (
    <div>
      <Header />
      <div className="fengshui-carousel">
        <div className="fengshui-carousel-image-wrapper" key={imageKey}>
          <img src={carouselItems[currentSlide].imageUrl} alt="" className="fengshui-carousel-image"/>
        </div>
        <div className="fengshui-carousel-left-text" key={`left-${textKey}`}>
          <h2>{carouselItems[currentSlide].leftTitle}</h2>
          <p>{carouselItems[currentSlide].leftText}</p>
        </div>
        <div className="fengshui-carousel-right-text" key={`right-${textKey}`}>
          <h2>{carouselItems[currentSlide].rightTitle}</h2>
          <p>{carouselItems[currentSlide].rightText}</p>
          {carouselItems[currentSlide].rightText2 && <h2>{carouselItems[currentSlide].rightText2}</h2>}
        </div>
        <div className="fengshui-carousel-dots">
          {carouselItems.map((_, index) => (
            <div
              key={index}
              className={`fengshui-dot ${index === currentSlide ? 'active' : ''}`}
              onClick={() => goToSlide(index)}
            ></div>
          ))}
        </div>
      </div>
      {/* New Section Below Carousel */}
      <div className="fengshui-section-title">
      Diagnostic Feng Shui
      </div>
      <div className="fengshui-layout">
        <div className="fengshui-column">
          <img src={exampleImage} alt="Example" className="fengshui-column-image"/>
        </div>
        <div className="fengshui-column">
        <h2 className="fengshui-h2">Individus</h2>
        <h3 className="fengshui-h3">Notre rôle est de définir</h3>
        <ul className="fengshui-ul">
            <li>La qualité du feng shui de la maison et son potentiel</li>
            <li>Analyse de l'environnement externe</li>
            <li>La circulation du qi à l'intérieur et à l'extérieur de votre maison</li>
          </ul>
          <h3 className="fengshui-h3">Nous vérifions</h3>
          <ul className="fengshui-ul">
            <li>Les différents Sha Qi (à l'intérieur et à l'extérieur de votre maison)</li>
            <li>L'orientation de la porte d'entrée</li>
            <li>Disposition interne de votre maison</li>
            <li>Positionnement des pièces (chambre, cuisine, salon...)</li>
          </ul>
          <h3 className="fengshui-h3">Nous dressons un plan détaillé de vos locaux à l'aide d'outils d'analyse feng shui.</h3>
          <ul className="fengshui-ul">
            <li>Vérifier les secteurs manquants ou les extensions (le cas échéant)</li>
            <li>Étude des trigrammes dans la maison</li>
            <li>Emplacement de votre cuisinière, four, bureau, canapé, lit et point d'eau</li>
          </ul>
        </div>
        <div className="fengshui-column">
        <h2 className="fengshui-h2">Professionnels</h2>
        <h3 className="fengshui-h3">Pour les professionnels</h3>
        <p className="fengshui-p"><i>Nous fournissons une expertise pour les bureaux, les magasins, les entreprises, les professionnels, les spas, les hôtels, les restaurants, les salons de coiffure, les salons de beauté, les chambres d'hôtes... ainsi que pour les entreprises (banques, écoles...).</i></p>
        <p className="fengshui-p"><i>Nos experts en feng shui détermineront vos objectifs et attentes afin de maximiser le succès et d'améliorer les performances de l'entreprise.</i></p>
        <h3 className="fengshui-h3">Nos experts vous aident</h3>
        <ul className="fengshui-ul">
          <li>Développement de l'entreprise,</li>
          <li>Augmentation des ventes de l'entreprise,</li>
          <li>Amélioration de la communication entre les membres de l'entreprise,</li>
          <li>Une solution pour réduire le stress</li>
          <li>Choisir des locaux, un magasin ou un terrain professionnel</li>
          <li>Repenser le logo, le site Web et la signalétique afin qu'ils soient en harmonie avec le secteur d'activité de l'entreprise... (Nos designers proposeront des logos et des sites Web basés sur une étude feng shui).</li>
        </ul>
        <p className="fengshui-p">Exemple d'expertise : cabinet médical, groupe français de cosmétiques industriels.</p>
      </div>
      </div>
    </div>
  );
};

export default FengShuiDiagnosis;