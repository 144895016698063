// useCarousel.js
import { useEffect, useRef, useCallback, useReducer } from 'react';
import img1 from '../assets/carousel/img1.jpg';
import img2 from '../assets/carousel/img2.jpg';
import img3 from '../assets/carousel/img3.jpg';
import img4 from '../assets/carousel/img4.jpg';
import img5 from '../assets/carousel/img5.jpg';

const actionTypes = {
  SET_CURRENT_IMG: 'SET_CURRENT_IMG',
  SET_ANIMATION_PHASE: 'SET_ANIMATION_PHASE',
  SET_IMAGE_ANIMATION_PHASE: 'SET_IMAGE_ANIMATION_PHASE',
  SET_SCROLLING: 'SET_SCROLLING',
  SET_CURRENT_ITEM: 'SET_CURRENT_ITEM',
  SET_IS_MOBILE: 'SET_IS_MOBILE',
};

const carouselReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_IMG:
      return { ...state, currentImg: action.payload };
    case actionTypes.SET_ANIMATION_PHASE:
      return { ...state, animationPhase: action.payload };
    case actionTypes.SET_IMAGE_ANIMATION_PHASE:
      return { ...state, imageAnimationPhase: action.payload };
    case actionTypes.SET_SCROLLING:
      return { ...state, scrolling: action.payload };
    case actionTypes.SET_CURRENT_ITEM:
      return { ...state, currentItem: action.payload };
    case actionTypes.SET_IS_MOBILE:
      return { ...state, isMobile: action.payload };
    default:
      return state;
  }
};

export const useCarousel = (carouselItems) => {
  const initialState = {
    currentImg: 0,
    currentItem: carouselItems[0],
    scrolling: false,
    animationPhase: '',
    imageAnimationPhase: '',
    isMobile: window.innerWidth <= 767,
  };

  const [state, dispatch] = useReducer(carouselReducer, initialState);
  const images = [img1, img2, img3, img4, img5];
  const autoAdvanceTimer = useRef(null);
  const currentImgRef = useRef(state.currentImg);

  // Update currentImgRef whenever state.currentImg changes
  useEffect(() => {
    currentImgRef.current = state.currentImg;
  }, [state.currentImg]);

  const resetAutoAdvanceTimer = useCallback(() => {
    clearInterval(autoAdvanceTimer.current);
    autoAdvanceTimer.current = setInterval(() => {
      if (state.isMobile) {
        dispatch({ type: actionTypes.SET_ANIMATION_PHASE, payload: 'fadeOut' });
      } else {
        dispatch({ type: actionTypes.SET_ANIMATION_PHASE, payload: 'fadeOutLeft' });
      }
      dispatch({ type: actionTypes.SET_IMAGE_ANIMATION_PHASE, payload: 'fadeOutScaleDown' });
      setTimeout(() => {
        const nextImgIndex = (currentImgRef.current + 1) % images.length;
        dispatch({ type: actionTypes.SET_CURRENT_IMG, payload: nextImgIndex });
        if (state.isMobile) {
          dispatch({ type: actionTypes.SET_ANIMATION_PHASE, payload: 'fadeIn' });
        } else {
          dispatch({ type: actionTypes.SET_ANIMATION_PHASE, payload: 'fadeInRight' });
        }
        dispatch({ type: actionTypes.SET_IMAGE_ANIMATION_PHASE, payload: 'fadeInScaleUp' });
      }, 500); // Matches the fade-out animation duration
    }, 10000); // Auto-advance every 10 seconds
  }, [images.length, state.isMobile]);

  useEffect(() => {
    const handleResize = () => {
      dispatch({ type: actionTypes.SET_IS_MOBILE, payload: window.innerWidth <= 767 });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      dispatch({ type: actionTypes.SET_SCROLLING, payload: window.scrollY > 0 });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    dispatch({ type: actionTypes.SET_CURRENT_ITEM, payload: carouselItems[state.currentImg] });
    resetAutoAdvanceTimer();
  }, [state.currentImg, carouselItems, resetAutoAdvanceTimer]);

  useEffect(() => {
    if (state.animationPhase === 'fadeInRight' || state.animationPhase === 'fadeIn') {
      const timer = setTimeout(() => {
        dispatch({ type: actionTypes.SET_ANIMATION_PHASE, payload: '' });
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [state.animationPhase]);

  useEffect(() => {
    resetAutoAdvanceTimer();
    return () => {
      clearInterval(autoAdvanceTimer.current);
    };
  }, [resetAutoAdvanceTimer]);

  const setImgIndex = useCallback((index) => {
    if (index !== state.currentImg) {
      if (state.isMobile) {
        dispatch({ type: actionTypes.SET_ANIMATION_PHASE, payload: 'fadeOut' });
      } else {
        dispatch({ type: actionTypes.SET_ANIMATION_PHASE, payload: 'fadeOutLeft' });
      }
      dispatch({ type: actionTypes.SET_IMAGE_ANIMATION_PHASE, payload: 'fadeOutScaleDown' });
      setTimeout(() => {
        dispatch({ type: actionTypes.SET_CURRENT_IMG, payload: index });
        if (state.isMobile) {
          dispatch({ type: actionTypes.SET_ANIMATION_PHASE, payload: 'fadeIn' });
        } else {
          dispatch({ type: actionTypes.SET_ANIMATION_PHASE, payload: 'fadeInRight' });
        }
        dispatch({ type: actionTypes.SET_IMAGE_ANIMATION_PHASE, payload: 'fadeInScaleUp' });
      }, 500);
    }
  }, [state.currentImg, state.isMobile]);

  return {
    currentImg: state.currentImg,
    currentItem: state.currentItem,
    scrolling: state.scrolling,
    animationPhase: state.animationPhase,
    imageAnimationPhase: state.imageAnimationPhase,
    setImgIndex,
    images,
    isMobile: state.isMobile,
  };
};
