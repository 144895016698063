import React, { useState } from 'react';
import './css/RenovationOptions.css';
import ModalRenovation from './ModalRenovation';

const RenovationButtons = () => {
    const [showQuestionnaire, setShowQuestionnaire] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [responses, setResponses] = useState([]);
    const [postalCode, setPostalCode] = useState('');
    const [surfaceArea, setSurfaceArea] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [isCompleted, setIsCompleted] = useState(false);

    const totalQuestions = 11;

    const handleButtonClick = () => {
        setShowQuestionnaire(true);
    };

    const handleQuestionClick = (response) => {
        // When not the final question or not submitting the project description
        if (currentQuestion < totalQuestions) {
            // Handle adding response to the state
            if (currentQuestion !== 10) { // Assuming case 11 is for project description
                setResponses(prev => [...prev, response]);
            }
            setCurrentQuestion(prev => prev + 1);
        } else {
            // For the final question, add description or indicate it was skipped
            if (response === 'next') {
                setResponses(prev => [...prev, projectDescription]);
            } else if (response === 'pass') {
                setResponses(prev => [...prev, 'Aucun détail supplémentaire fourni.']);
            }
            setIsCompleted(true); // Mark questionnaire as complete
        }
    };

    const handlePostalCodeChange = (event) => {
        setPostalCode(event.target.value);
    };

    const handleSurfaceAreaChange = (event) => {
        setSurfaceArea(event.target.value);
    };

    const handleKeyPress = (event, inputType) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            let newResponses = [...responses];

            if (inputType === 'postalCode') {
                newResponses.push(postalCode);
            } else if (inputType === 'surfaceArea') {
                newResponses.push(surfaceArea);
            }

            setResponses(newResponses);

            if (currentQuestion < totalQuestions) {
                setCurrentQuestion(currentQuestion + 1);
            }
        }
    };

    const handlePreviousClick = () => {
        if (currentQuestion > 1) {
            setCurrentQuestion(currentQuestion - 1);
        }
    };

    const closeQuestionnaire = () => {
        setShowQuestionnaire(false);
        setCurrentQuestion(1);
        setResponses([]);
        setPostalCode('');
        setSurfaceArea('');
        setProjectDescription('');
        setIsCompleted(false); // Reset completion status
    };

    const renderQuestion = () => {
        switch (currentQuestion) {
            case 1:
                return (
                    <>
                        <h2>Comment pouvons-nous vous aider ?</h2>
                        <div className="question" onClick={() => handleQuestionClick('company')}>Je cherche une entreprise pour réaliser mes travaux</div>
                        <div className="question" onClick={() => handleQuestionClick('quote')}>J'ai besoin d'un devis pour ma banque</div>
                        <div className="question" onClick={() => handleQuestionClick('inspiration')}>Je cherche de l'inspiration et des conseils</div>
                        <div className="question" onClick={() => handleQuestionClick('estimate')}>Je souhaite une estimation pour mon projet</div>
                        <div className="question" onClick={() => handleQuestionClick('unknown')}>Je ne sais pas</div>
                    </>
                );

            case 2:
                return (
                    <>
                        <h2>Quel est le code postal de votre site ?</h2>
                        <p>Utilisez votre code postal pour affiner votre estimation de prix.</p>
                        <input
                            type="text"
                            value={postalCode}
                            onChange={handlePostalCodeChange}
                            onKeyPress={handleKeyPress} // Add this line
                            placeholder="Entrez votre code postal"
                            className="postal-code-input"
                        />
                    </>
                );
            case 3:
                return (
                    <>
                        <h2>Quel type de projet souhaitez-vous réaliser ?</h2>
                        <div className="question" onClick={() => handleQuestionClick('complete')}>Rénovation complète</div>
                        <div className="question" onClick={() => handleQuestionClick('partial')}>Rénovation partielle</div>
                        <div className="question" onClick={() => handleQuestionClick('extension')}>Extension</div>
                        <div className="question" onClick={() => handleQuestionClick('raising')}>Surélévation</div>
                        <div className="question" onClick={() => handleQuestionClick('energy')}>Rénovation énergétique</div>
                        <div className="question" onClick={() => handleQuestionClick('construction')}>Construction</div>
                        <div className="question" onClick={() => handleQuestionClick('fittings')}>Aménagements extérieurs</div>
                    </>
                );

            case 4:
                return (
                    <>
                        <h2>Quel bien est concerné ?</h2>
                        <div className="question" onClick={() => handleQuestionClick('apartment')}>Appartement</div>
                        <div className="question" onClick={() => handleQuestionClick('house')}>Maison</div>
                        <div className="question" onClick={() => handleQuestionClick('building')}>Immeuble</div>
                        <div className="question" onClick={() => handleQuestionClick('business')}>Local commercial</div>
                        <div className="question" onClick={() => handleQuestionClick('other')}>Autre</div>
                    </>
                );
            case 5:
                return (
                    <>
                        <h2>Quelle est la surface du bien à rénover ?</h2>
                        <p>Entrez la surface totale des pièces concernées par les travaux.</p>
                        <input
                            type="text"
                            value={surfaceArea}
                            onChange={handleSurfaceAreaChange}
                            onKeyPress={(e) => handleKeyPress(e, 'surfaceArea')}
                            placeholder="Entrez la surface en m²"
                            className="postal-code-input" // Reuse the styling for consistency
                        />
                    </>
                );
            case 6:
                return (
                    <>
                        <h2>Où en êtes-vous dans votre projet ?</h2>
                        <div className="question" onClick={() => handleQuestionClick('visits')}>J'ai fait quelques visites</div>
                        <div className="question" onClick={() => handleQuestionClick('offer')}>J'ai fait une offre</div>
                        <div className="question" onClick={() => handleQuestionClick('compromise')}>J'ai signé mon compromis</div>
                        <div className="question" onClick={() => handleQuestionClick('owner')}>Je suis propriétaire</div>
                        <div className="question" onClick={() => handleQuestionClick('curious')}>Je suis juste curieux</div>
                    </>
                );
            case 7:
                return (
                    <>
                        <h2>Quand aimeriez-vous commencer les travaux ?</h2>
                        <p>En fonction de l'urgence des travaux, le coût de votre projet peut varier.</p>
                        <div className="question" onClick={() => handleQuestionClick('beforejune')}>Avant juin 2024</div>
                        <div className="question" onClick={() => handleQuestionClick('betweenjuneseptember')}>Entre juin 2024 et septembre 2024</div>
                        <div className="question" onClick={() => handleQuestionClick('afterseptember')}>Après septembre 2024</div>
                    </>
                );
            case 8:
                return (
                    <>
                        <h2>Avez-vous besoin d'un architecte ?</h2>
                        <p>Avec le soutien d'Archibuild, une équipe d'architectes peut vous aider pour la disposition, la structure et le design intérieur.</p>
                        <div className="question" onClick={() => handleQuestionClick('yes')}>Oui</div>
                        <div className="question" onClick={() => handleQuestionClick('no')}>Non</div>
                        <div className="question" onClick={() => handleQuestionClick('haveone')}>J'en ai déjà un</div>
                    </>
                );
            case 9:
                return (
                    <>
                        <h2>Quel est le critère de sélection le plus important ?</h2>
                        <div className="question" onClick={() => handleQuestionClick('quality')}>Qualité</div>
                        <div className="question" onClick={() => handleQuestionClick('leadtimes')}>Délais</div>
                        <div className="question" onClick={() => handleQuestionClick('price')}>Prix</div>
                        <div className="question" onClick={() => handleQuestionClick('advice')}>Conseils</div>
                        <div className="question" onClick={() => handleQuestionClick('followup')}>Suivi</div>
                        <div className="question" onClick={() => handleQuestionClick('other')}>Autre</div>
                    </>
                );
            case 10:
                return (
                    <>
                        <h2>Quel est votre budget ?</h2>
                        <div className="question" onClick={() => handleQuestionClick('less10k')}>Moins de 10 000 €</div>
                        <div className="question" onClick={() => handleQuestionClick('between10k30k')}>Entre 10 000 € et 30 000 €</div>
                        <div className="question" onClick={() => handleQuestionClick('between30k50k')}>Entre 30 000 € et 50 000 €</div>
                        <div className="question" onClick={() => handleQuestionClick('between50k100k')}>Entre 50 000 € et 100 000 €</div>
                        <div className="question" onClick={() => handleQuestionClick('between100k250k')}>Entre 100 000 € et 250 000 €</div>
                        <div className="question" onClick={() => handleQuestionClick('more250k')}>Plus de 250 000 €</div>
                    </>
                );
            case 11:
                return (
                    <>
            <h2>Aimeriez-vous décrire votre projet plus en détail ?</h2>
            <p>N'indiquez pas vos coordonnées ici.</p>
            <textarea
                value={projectDescription}
                onChange={(e) => setProjectDescription(e.target.value)}
                placeholder="Décrivez votre projet ici"
                className="project-description-input"
            />
            <div className="description-buttons-container">
                <button className="pass-button" onClick={() => handleQuestionClick('pass')}>Passer</button>
                <button className="next-button" onClick={() => handleQuestionClick('next')}>Suivant</button>
            </div>
        </>
    );

            default:
                return <div>Question non trouvée</div>;
        }
    };

    return (
        <>
        <div className="project-title">
            <h2>Vous avez un projet en tête ?</h2>
            <h3>Remplissez notre enquête</h3>
        </div>
        <div className="renovation-options-container">
            <button className="renovation-option" onClick={handleButtonClick}>
                <span className="action-phrase">Rénovation de Propriété <span className="arrow">&#x2192;</span></span>
            </button>
            <button className="renovation-option" onClick={handleButtonClick}>
                <span className="action-phrase">Rénovation de Bureau <span className="arrow">&#x2192;</span></span>
            </button>
            <button className="renovation-option" onClick={handleButtonClick}>
                <span className="action-phrase">Expertise FENG SHUI<span className="arrow">&#x2192;</span></span>
            </button>
            <button className="renovation-option" onClick={handleButtonClick}>
                <span className="action-phrase">Agrandissement de Propriété<span className="arrow">&#x2192;</span></span>
            </button>
        </div>
        <ModalRenovation isOpen={showQuestionnaire} onClose={closeQuestionnaire}>
  {!isCompleted ? (
    <>
      <div className="progress-container">
          <div className="progress-bar" style={{ width: `${(currentQuestion / totalQuestions) * 100}%` }}>
            {currentQuestion}/{totalQuestions}
          </div>
      </div>
      {currentQuestion > 1 && (
        <button className="previous-question" onClick={handlePreviousClick}>
          Question Précédente
        </button>
      )}
      {renderQuestion()}
    </>
  ) : (
    <div className="completion-message">
      <h2>Merci !</h2>
      <p>Vos réponses ont été enregistrées. Voici un récapitulatif de vos choix :</p>
      <ul>
        {responses.map((response, index) => (
          <li key={index}>{response}</li>
        ))}
      </ul>
    </div>
  )}
</ModalRenovation>
    </>
    );
};

export default RenovationButtons;
