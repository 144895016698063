// HomePage.js
import React from 'react';
import Header from './header';
import './css/HomePage.css';
import MainGallery from './MainGallery';
import PricePerSquareMeterWidget from './PricePerSquareMeterWidget';
import RenovationOptions from './RenovationOptions';
import RenovationButtons from './RenovationButtons';
import OnlineGallery from './OnlineGallery';
import useScrollAnimation from './useScrollAnimation';
import Team from './Team';
import Intro from './intro';
import Artist from './Artist';
import Footer from './Footer';
import MenuSplit from './MenuSplit';
import Carousel from './Carousel';

function HomePage() {
  const visibility = useScrollAnimation([
    'renovation-options',
    'maingallery',
    'online-gallery',
    'renovation-buttons',
    'price-per-square-meter-widget',
    'team',
    'intro',
  ]);

  return (
    <>
      <Header />
      <Carousel />
      
      <div className={`renovation-options ${visibility['renovation-options'] ? 'slide-fade-twist-left visible' : 'hidden'}`}>
        <RenovationOptions />
      </div>
      
      <MenuSplit />

      <div className={`intro ${visibility['intro'] ? 'slide-fade-scale-bottom visible' : 'hidden'}`}>
        <Intro />
      </div>

      <div className={`maingallery ${visibility['maingallery'] ? 'slide-fade-twist-right visible' : 'hidden'}`}>
        <MainGallery />
      </div>

      <div className={`online-gallery ${visibility['online-gallery'] ? 'slide-fade-scale-bottom visible' : 'hidden'}`}>
        <OnlineGallery />
      </div>

      <Artist />

      <div className={`renovation-buttons ${visibility['renovation-buttons'] ? 'slide-fade-twist-right visible' : 'hidden'}`}>
        <RenovationButtons />
      </div>

      <div className={`price-per-square-meter-widget ${visibility['price-per-square-meter-widget'] ? 'slide-fade-twist-left visible' : 'hidden'}`}>
        <PricePerSquareMeterWidget />
      </div>

      <div className={`team ${visibility['team'] ? 'slide-fade-twist-right visible' : 'hidden'}`}>
        <Team />
      </div>
      <Footer />
    </>
  );
}

export default HomePage;
