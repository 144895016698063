import React, { useEffect, useState, useRef } from 'react';
import "@babylonjs/core/Debug/debugLayer";
import "@babylonjs/inspector";
import "@babylonjs/loaders/glTF";
import {
  Engine,
  Scene,
  UniversalCamera,
  Vector3,
  SceneLoader,
  HemisphericLight,
  DirectionalLight,
  ActionManager,
  ExecuteCodeAction,
} from "@babylonjs/core";

import galleryGLB from "../Art/gallery/compressed.glb";
import artworks from './artworks';

function Art() {
  const [cameraPosition, setCameraPosition] = useState({ x: 0, y: 0, z: 0 });
  const [currentArtwork, setCurrentArtwork] = useState({});
  const fixedYPosition = 2.5;  // Define a constant Y position

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  // Refs for joystick divs
  const leftJoystickDivRef = useRef(null);
  const rightJoystickDivRef = useRef(null);

  // Refs for joystick handles
  const leftJoystickHandleRef = useRef(null);
  const rightJoystickHandleRef = useRef(null);

  // Joystick input refs
  const leftJoystickInput = useRef({ active: false, deltaX: 0, deltaY: 0, touchId: null });
  const rightJoystickInput = useRef({ active: false, deltaX: 0, deltaY: 0, touchId: null });

  useEffect(() => {
    const canvas = document.createElement("canvas");
    canvas.style.position = "fixed";
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.width = "100%";
    canvas.style.height = "100vh";
    canvas.style.zIndex = -1;
    canvas.id = "gameCanvas";
    document.body.appendChild(canvas);

    document.body.style.margin = 0;
    document.body.style.overflow = "hidden";

    const engine = new Engine(canvas, true, {
      adaptToDeviceRatio: true,
      limitDeviceRatio: true,
    });

    const scene = new Scene(engine);
    scene.collisionsEnabled = true;

    const camera = new UniversalCamera("camera", new Vector3(-35.55, fixedYPosition, -18.65), scene);
    camera.speed = isMobile ? 0.1 : 0.25;  // Fine-tune camera speed
    camera.angularSensibility = isMobile ? 10000 : 5000;  // Fine-tune sensitivity
    camera.minZ = 0.1;
    camera.applyGravity = false;
    camera.checkCollisions = true;
    camera.ellipsoid = new Vector3(1, 1, 1);

    const leftJoystickDiv = leftJoystickDivRef.current;
    const rightJoystickDiv = rightJoystickDivRef.current;

    if (isMobile) {
      // Remove the default camera controls
      camera.detachControl(canvas);

      // Left joystick event listeners
      if (leftJoystickDiv) {
        leftJoystickDiv.addEventListener('touchstart', handleLeftJoystickStart, false);
        leftJoystickDiv.addEventListener('touchmove', handleLeftJoystickMove, false);
        leftJoystickDiv.addEventListener('touchend', handleLeftJoystickEnd, false);
        leftJoystickDiv.addEventListener('touchcancel', handleLeftJoystickEnd, false);
      }

      // Right joystick event listeners
      if (rightJoystickDiv) {
        rightJoystickDiv.addEventListener('touchstart', handleRightJoystickStart, false);
        rightJoystickDiv.addEventListener('touchmove', handleRightJoystickMove, false);
        rightJoystickDiv.addEventListener('touchend', handleRightJoystickEnd, false);
        rightJoystickDiv.addEventListener('touchcancel', handleRightJoystickEnd, false);
      }
    } else {
      // Attach default controls for desktop
      camera.attachControl(canvas, true);
    }

    const hemiLight = new HemisphericLight("hemiLight", new Vector3(0, 1, 0), scene);
    hemiLight.intensity = 0.6;

    const dirLight = new DirectionalLight("dirLight", new Vector3(-1, -2, -1), scene);
    dirLight.position = new Vector3(-12.54, 29.37, -79.41);
    dirLight.intensity = 0.7;

    engine.displayLoadingUI();

    SceneLoader.ImportMeshAsync("", galleryGLB, "").then(function (result) {
      engine.hideLoadingUI();
      result.meshes.forEach(mesh => {
        mesh.checkCollisions = true;

        if (artworks[mesh.name]) {
          mesh.actionManager = new ActionManager(scene);
          mesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
            setCurrentArtwork(artworks[mesh.name]);
          }));
        }
      });
    });

    engine.runRenderLoop(() => {
      if (isMobile) {
        const deltaTime = engine.getDeltaTime();

        // Reset camera direction
        camera.cameraDirection.set(0, 0, 0);

        // Handle left joystick movement (camera movement)
        if (leftJoystickInput.current.active) {
          const speed = camera.speed * deltaTime / 16.6667; // Normalize to 60 FPS

          const forward = camera.getDirection(Vector3.Forward());
          const right = camera.getDirection(Vector3.Right());

          const deltaMovement = forward.scale(-leftJoystickInput.current.deltaY * speed * 2).add(
            right.scale(leftJoystickInput.current.deltaX * speed * 2)
          );

          // Move the camera with collisions by adjusting cameraDirection
          camera.cameraDirection.addInPlace(deltaMovement);
        }

        // Handle right joystick movement (camera rotation)
        if (rightJoystickInput.current.active) {
          const angularSpeed = 0.05 * deltaTime / 16.6667; // Adjust rotation speed

          camera.rotation.y -= rightJoystickInput.current.deltaX * angularSpeed;
          camera.rotation.x -= rightJoystickInput.current.deltaY * angularSpeed;

          const maxRotationX = Math.PI / 2 - 0.1;
          const minRotationX = -Math.PI / 2 + 0.1;
          camera.rotation.x = Math.max(minRotationX, Math.min(maxRotationX, camera.rotation.x));
        }
      }

      // Fix the camera's Y position
      camera.position.y = fixedYPosition;

      scene.render();
      setCameraPosition({
        x: parseFloat(camera.position.x.toFixed(2)),
        y: parseFloat(camera.position.y.toFixed(2)),
        z: parseFloat(camera.position.z.toFixed(2)),
      });
    });

    window.addEventListener('resize', () => {
      engine.resize();
    });

    // Define the handlers
    function handleLeftJoystickStart(e) {
      e.preventDefault();
      const touch = e.targetTouches[0];
      leftJoystickInput.current.active = true;
      leftJoystickInput.current.touchId = touch.identifier;
    }

    function handleLeftJoystickMove(e) {
      e.preventDefault();
      const touch = [...e.changedTouches].find(t => t.identifier === leftJoystickInput.current.touchId);
      if (touch && leftJoystickInput.current.active) {
        const rect = leftJoystickDiv.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        let deltaX = touch.pageX - centerX;
        let deltaY = touch.pageY - centerY;

        // Cap the deltaX and deltaY to the maximum radius
        const maxRadius = 40; // Smaller joystick, so reduce radius
        const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
        if (distance > maxRadius) {
          const ratio = maxRadius / distance;
          deltaX *= ratio;
          deltaY *= ratio;
        }

        // Normalize deltaX and deltaY
        leftJoystickInput.current.deltaX = deltaX / maxRadius;
        leftJoystickInput.current.deltaY = deltaY / maxRadius;

        // Move the joystick handle
        const handle = leftJoystickHandleRef.current;
        if (handle) {
          handle.style.transform = `translate(${deltaX}px, ${deltaY}px)`;
        }
      }
    }

    function handleLeftJoystickEnd(e) {
      e.preventDefault();
      const touch = [...e.changedTouches].find(t => t.identifier === leftJoystickInput.current.touchId);
      if (touch) {
        leftJoystickInput.current.active = false;
        leftJoystickInput.current.deltaX = 0;
        leftJoystickInput.current.deltaY = 0;
        leftJoystickInput.current.touchId = null;

        // Reset the joystick handle position
        const handle = leftJoystickHandleRef.current;
        if (handle) {
          handle.style.transform = `translate(0px, 0px)`;
        }
      }
    }

    function handleRightJoystickStart(e) {
      e.preventDefault();
      const touch = e.targetTouches[0];
      rightJoystickInput.current.active = true;
      rightJoystickInput.current.touchId = touch.identifier;
    }

    function handleRightJoystickMove(e) {
      e.preventDefault();
      const touch = [...e.changedTouches].find(t => t.identifier === rightJoystickInput.current.touchId);
      if (touch && rightJoystickInput.current.active) {
        const rect = rightJoystickDiv.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        let deltaX = touch.pageX - centerX;
        let deltaY = touch.pageY - centerY;

        // Cap the deltaX and deltaY to the maximum radius
        const maxRadius = 40; // Smaller joystick, so reduce radius
        const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
        if (distance > maxRadius) {
          const ratio = maxRadius / distance;
          deltaX *= ratio;
          deltaY *= ratio;
        }

        // Normalize deltaX and deltaY
        rightJoystickInput.current.deltaX = deltaX / maxRadius;
        rightJoystickInput.current.deltaY = deltaY / maxRadius;

        // Move the joystick handle
        const handle = rightJoystickHandleRef.current;
        if (handle) {
          handle.style.transform = `translate(${deltaX}px, ${deltaY}px)`;
        }
      }
    }

    function handleRightJoystickEnd(e) {
      e.preventDefault();
      const touch = [...e.changedTouches].find(t => t.identifier === rightJoystickInput.current.touchId);
      if (touch) {
        rightJoystickInput.current.active = false;
        rightJoystickInput.current.deltaX = 0;
        rightJoystickInput.current.deltaY = 0;
        rightJoystickInput.current.touchId = null;

        // Reset the joystick handle position
        const handle = rightJoystickHandleRef.current;
        if (handle) {
          handle.style.transform = `translate(0px, 0px)`;
        }
      }
    }

    return () => {
      engine.dispose();
      document.body.removeChild(canvas);
      document.body.style.margin = "";
      document.body.style.overflow = "";

      if (isMobile) {
        // Remove event listeners
        if (leftJoystickDiv) {
          leftJoystickDiv.removeEventListener('touchstart', handleLeftJoystickStart, false);
          leftJoystickDiv.removeEventListener('touchmove', handleLeftJoystickMove, false);
          leftJoystickDiv.removeEventListener('touchend', handleLeftJoystickEnd, false);
          leftJoystickDiv.removeEventListener('touchcancel', handleLeftJoystickEnd, false);
        }

        if (rightJoystickDiv) {
          rightJoystickDiv.removeEventListener('touchstart', handleRightJoystickStart, false);
          rightJoystickDiv.removeEventListener('touchmove', handleRightJoystickMove, false);
          rightJoystickDiv.removeEventListener('touchend', handleRightJoystickEnd, false);
          rightJoystickDiv.removeEventListener('touchcancel', handleRightJoystickEnd, false);
        }
      }
    };
  }, [isMobile]);

  const handleClose = () => {
    setCurrentArtwork({});
  };

  // Define joystick styles
  const joystickContainerStyle = {
    position: 'fixed',
    bottom: '20px',
    width: '100px',
    height: '100px',
    borderRadius: '50px',
    touchAction: 'none',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255,255,255,0.2)',
  };

  const joystickStyle = {
    width: '50px',
    height: '50px',
    borderRadius: '25px',
    background: 'radial-gradient(circle at center, #ffffff 0%, #cccccc 100%)',
    position: 'relative',
    left: 0,
    top: 0,
    boxShadow: '0 0 10px rgba(0,0,0,0.5)',
    backdropFilter: 'blur(5px)',
    border: '1px solid rgba(255,255,255,0.3)',
    transition: 'transform 0.1s ease-out', // Smooth transition
  };

  const leftJoystickContainerStyle = {
    ...joystickContainerStyle,
    left: '20px',
    background: 'radial-gradient(circle at center, rgba(0,0,0,0.4), rgba(0,0,0,0.2))',
    boxShadow: 'inset 0 0 10px rgba(0,0,0,0.5)',
  };

  const rightJoystickContainerStyle = {
    ...joystickContainerStyle,
    right: '20px',
    background: 'radial-gradient(circle at center, rgba(0,0,0,0.4), rgba(0,0,0,0.2))',
    boxShadow: 'inset 0 0 10px rgba(0,0,0,0.5)',
  };

  return (
    <>
      <div style={{
        position: 'fixed',
        top: '10px',
        right: '10px',
        padding: '5px 10px',
        backgroundColor: 'rgba(0,0,0,0.7)',
        color: 'white',
        borderRadius: '5px',
        zIndex: 1000,
        fontSize: isMobile ? '12px' : '14px',
      }}>
        X: {cameraPosition.x} | Y: {cameraPosition.y} | Z: {cameraPosition.z}
      </div>
      {currentArtwork.image && (
        <div style={{
          position: 'fixed',
          bottom: '10px',
          left: '10px',
          width: '90%',
          maxWidth: '300px',
          padding: '10px',
          backgroundColor: 'rgba(0,0,0,0.8)',
          color: 'white',
          borderRadius: '10px',
          zIndex: 1000,
          textAlign: 'center',
        }}>
          <button onClick={handleClose} style={{
            position: 'absolute',
            top: '5px',
            right: '10px',
            border: 'none',
            background: 'transparent',
            color: 'white',
            fontSize: '24px',
            cursor: 'pointer',
          }}>×</button>
          <div style={{ fontSize: isMobile ? '14px' : '16px' }}>{currentArtwork.artistName}</div>
          <div style={{ marginTop: '5px', fontSize: '14px', fontStyle: 'italic' }}>{currentArtwork.paintingDescription}</div>
          <img src={currentArtwork.image} alt={currentArtwork.paintingDescription} style={{
            width: '100%',
            marginTop: '10px',
            maxHeight: isMobile ? '150px' : '200px',
            objectFit: 'contain',
          }} />
          <div style={{ marginTop: '5px', fontSize: isMobile ? '12px' : '14px' }}>{currentArtwork.currentDescription}</div>
        </div>
      )}
      {isMobile && (
        <>
          <div
            id="leftJoystick"
            ref={leftJoystickDivRef}
            style={leftJoystickContainerStyle}
          >
            <div
              id="leftJoystickHandle"
              ref={leftJoystickHandleRef}
              style={joystickStyle}
            ></div>
          </div>
          <div
            id="rightJoystick"
            ref={rightJoystickDivRef}
            style={rightJoystickContainerStyle}
          >
            <div
              id="rightJoystickHandle"
              ref={rightJoystickHandleRef}
              style={joystickStyle}
            ></div>
          </div>
        </>
      )}
    </>
  );
}

export default Art;
