import React from 'react';
import Header from '../frontpage/header'; // Adjust the import path if necessary


const Architecture = () => (
  <div>
    <Header />

    {/* Content goes here */}
  </div>
);

export default Architecture;