import React from 'react';
import ReactDOM from 'react-dom';
import './css/RenovationOptions.css'; // Re-using styles from RenovationOptions

const ModalRenovation = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <>
      <div className="modal-backdrop" onClick={onClose} />
      <div className="modal-style">
        <button className="close-button" onClick={onClose}>X</button>
        {children}
      </div>
    </>,
    document.getElementById('modal-root')
  );
};

export default ModalRenovation;