import React from 'react';
import './css/MenuSplit.css';
import video1 from '../assets/menusplit/video1.mp4';
import video2 from '../assets/menusplit/video2.mp4';
import video3 from '../assets/menusplit/video3.mp4';

class MenuSplit extends React.Component {
  componentDidMount() {
    const videos = document.querySelectorAll('#menusplit video');

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.play().catch((error) => {
            // Handle the error
            console.log('Error attempting to play', error);
          });
        } else {
          entry.target.pause();
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    videos.forEach((video) => {
      observer.observe(video);
    });
  }

  render() {
    return (
      <div id="menusplit">
        <header>
          <div>
            <video src={video1} muted loop playsInline></video>
            <div className="text">
              <p className="sub-heading">
                <b>Agence Bostyl</b>&#47;<span>Paris</span>
              </p>
              <h1 className="title">Architecture d'intérieur</h1>
              <p>
                Une nouvelle approche qui allie l’architecture intérieure, le respect de l'environnement et le
                bien-être global.
              </p>
              <a href="/Architecture" className="article-link">
                La boutique
              </a>
            </div>
          </div>
          <div>
            <video src={video2} muted loop playsInline></video>
            <div className="text">
              <p className="sub-heading">
                <b>Agence Bostyl</b>&#47;<span>Paris</span>
              </p>
              <h1 className="title">Réalisations</h1>
              <p>
                Bénéficiez de notre expérience dans la conception et le suivi de chantier de villas, maisons
                individuelles, extensions, architecture intérieure, appartements, boutiques, aménagement
                paysager, mobilier...
              </p>
              <a href="/Interiordesign" className="article-link">
                Nos réalisations
              </a>
            </div>
          </div>
          <div>
            <video src={video3} muted loop playsInline></video>
            <div className="text">
              <p className="sub-heading">
                <b>Agence Bostyl</b>&#47;<span>Paris</span>
              </p>
              <h1 className="title">Galerie d'art</h1>
              <p>
              Entrez dans notre galerie d’art en ligne immersive, où chaque œuvre prend vie en 3D. 
              Parcourez un espace virtuel qui vous permet de découvrir des peintures sous tous les angles. 
              Notre galerie 3D vous offre une expérience unique, plongeant dans l’art contemporain avec un réalisme captivant. 
              </p>
              <a href="/art" className="article-link">
                La galerie
              </a>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default MenuSplit;
