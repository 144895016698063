import React from 'react';
import './css/Footer.css'; // Ensure you have this path correct for your CSS file
import logo from '../assets/logos/logo.jpg'; // Update this path to the location of your logo
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="App-footer">
      <div className="footer-content">
        <div className="footer-column left-column">
          <h3 className="footer-heading">Liens utiles</h3>
          <ul className="footer-list">
            <li><a href="/account" className="footer-link">Mon compte</a></li>
            <li><a href="/order-history" className="footer-link">Historique de vos commandes</a></li>
            <li><a href="/guest-order-tracking" className="footer-link">Suivi de commande invité</a></li>
            <li><a href="/wishlist" className="footer-link">Liste de souhaits</a></li>
            <li><a href="/product-comparison" className="footer-link">Comparateur produit</a></li>
          </ul>
        </div>
        <div className="footer-column center-column">
          <h3 className="footer-heading">Social Media</h3>
          <div className="footer-social-media">
            <a href="https://twitter.com" className="social-link"><FaTwitter /></a>
            <a href="https://facebook.com" className="social-link"><FaFacebook /></a>
            <a href="https://instagram.com" className="social-link"><FaInstagram /></a>
          </div>
          <div className="footer-logo-container">
            <a href="/">
              <img src={logo} alt="Logo" className="footer-logo" />
            </a>
          </div>
        </div>
        <div className="footer-column right-column">
          <h3 className="footer-heading">Contactez-nous</h3>
          <address>
            Agence Bostyl<br />
            91 Rue du Faubourg Saint-Honoré<br />
            75008 Paris<br />
            <a href="tel:+33699606868" className="footer-link">+33 (0)6 99 60 68 68</a><br />
            <a href="mailto:info@agencebostyl.com" className="footer-link">info@agencebostyl.com</a>
          </address>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
