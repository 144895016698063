import { useState, useEffect } from 'react';

const useScrollAnimation = (elements) => {
  const [isVisible, setIsVisible] = useState(
    elements.reduce((acc, curr) => {
      acc[curr] = false;
      return acc;
    }, {})
  );

  useEffect(() => {
    const handleScroll = () => {
      const screenPos = window.innerHeight;

      // Check if we're at the very top of the page
      if (window.scrollY === 0) {
        // Reset all animations when at the top
        setIsVisible(elements.reduce((acc, curr) => {
          acc[curr] = false;
          return acc;
        }, {}));
      } else {
        elements.forEach(element => {
          const domElement = document.querySelector(`.${element}`);
          if (domElement) {
            const position = domElement.getBoundingClientRect();
            // Check if the element is in view and update its visibility
            if (position.top < screenPos && position.bottom > 0) {
              setIsVisible(prev => ({ ...prev, [element]: true }));
            }
          }
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [elements]);

  return isVisible;
};

export default useScrollAnimation;