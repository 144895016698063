// Modal.js
import React from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ onClose, children, className = '' }) => {
  return ReactDOM.createPortal(
    <div className={`modal ${className}`} onClick={onClose}>
      {children}
    </div>,
    document.getElementById('modal-root')
  );
};

export default Modal;