import React, { useState } from 'react';
import './css/HomePage.css';

const PricePerSquareMeterWidget = () => {
    const [area, setArea] = useState('');
    const [totalPrice, setTotalPrice] = useState('');

    const pricePerSquareMeter = 30;

    const calculateTotalPrice = () => {
        if (area > 0) {
            const total = area * pricePerSquareMeter;
            setTotalPrice(total.toFixed(2));
        } else {
            setTotalPrice('');
            alert('La surface doit être supérieure à 0.');
        }
    };

    return (
        <div className="base-container">
            <div className="intro-container">
                <p>Bienvenue dans notre Outil d'Aménagement d'Espace Interactif. Dans le domaine de l'architecture d'intérieur en ligne, la précision dans la planification et l'allocation budgétaire est essentielle. Notre outil est conçu pour vous fournir une estimation immédiate du coût de votre projet en fonction de la superficie totale de votre espace. Il vous suffit d'entrer les mètres carrés de la zone que vous prévoyez de transformer, et notre calculatrice vous présentera une estimation du prix total, en supposant un tarif standard de 30 € par mètre carré. Cette estimation aide à rationaliser le processus de planification budgétaire, vous permettant de prendre des décisions éclairées alors que vous vous lancez dans votre parcours pour créer des espaces qui résonnent avec élégance et fonctionnalité.</p>
            </div>
            <div className="widget-container">
                <h2 className="widget-title">Calculateur de prix total</h2>
                <input
                    className="widget-input"
                    type="number"
                    placeholder="Superficie en mètres carrés"
                    value={area}
                    onChange={(e) => setArea(e.target.value)}
                />
                <button
                    className="widget-button"
                    onClick={calculateTotalPrice}
                >
                    Calculer
                </button>
                {totalPrice && (
                    <p className="widget-result">Prix total : {totalPrice} €</p>
                )}
            </div>
        </div>
    );
};

export default PricePerSquareMeterWidget;
