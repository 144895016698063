import React, { useState, useEffect, useCallback, useRef } from 'react';
import Header from '../frontpage/header';
import './css/Interiordesign.css';
import img1 from './assets/InteriorDesign/img1.jpg';
import img2 from './assets/InteriorDesign/img2.jpg';
import exampleImage from './assets/InteriorDesign/example.jpg';

const carouselItems = [
  {
    id: 1,
    leftTitle: "De la Création à la Réalisation",
    leftText: "Nous nous occupons de chaque étape : Plans, design intérieur, aménagement paysager, choix des matériaux.",
    rightTitle: "Une équipe d'experts dédiée à vous",
    rightText: "Une nouvelle approche de l'architecture intérieure, le respect de l'environnement et le bien-être général.",
    imageUrl: img1,
  },
  {
    id: 2,
    leftTitle: "Revitalisez Votre Espace : Service d'Architecture Intérieure en Ligne Abordable",
    leftText: "Revitalisez votre espace avec notre service d'architecture intérieure en ligne. Laissez des experts concevoir un environnement fonctionnel, sain et accueillant sans vous ruiner. Bénéficiez de l'expertise d'une agence de premier plan, sans les frais de déplacement.",
    rightTitle: "IDENTIFIER - CAPTURER - MODELER",
    rightText: "L'expertise en Feng Shui vise à rétablir une véritable qualité énergétique dans les espaces de vie. Un environnement harmonieux est créé dans le but d'améliorer l'équilibre personnel et/ou professionnel et la vie quotidienne. En termes concrets, cette science apporte du bien-être et aide à se protéger contre les énergies néfastes. Elle nous permet de capturer et d'exploiter tout ce qui est favorable, et offre des solutions durables.",
    rightText2: "CONNAÎTRE - AMÉLIORER - PRÉVOIR",
    imageUrl: img2,
  },
];

const Interiordesign = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [textKey, setTextKey] = useState(0);
  const [imageKey, setImageKey] = useState(0);
  const intervalRef = useRef();

  const startSlideShow = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      setTextKey(prevKey => prevKey + 1);
      setImageKey(prevKey => prevKey + 1);
      setCurrentSlide((prevSlide) => (prevSlide + 1) % carouselItems.length);
    }, 10000);
  }, []);

  useEffect(() => {
    startSlideShow();
    return () => clearInterval(intervalRef.current);
  }, [startSlideShow]);

  const goToSlide = useCallback((index) => {
    if (index !== currentSlide) {
      setCurrentSlide(index);
      setTextKey(prevKey => prevKey + 1);
      setImageKey(prevKey => prevKey + 1);
      startSlideShow();
    }
  }, [currentSlide, startSlideShow]);

  return (
    <div>
      <Header />
      <div className="interiordesign-carousel">
        <div className="interiordesign-carousel-image-wrapper" key={imageKey}>
          <img src={carouselItems[currentSlide].imageUrl} alt="" className="interiordesign-carousel-image"/>
        </div>
        <div className="interiordesign-carousel-left-text" key={`left-${textKey}`}>
          <h2>{carouselItems[currentSlide].leftTitle}</h2>
          <p>{carouselItems[currentSlide].leftText}</p>
        </div>
        <div className="interiordesign-carousel-right-text" key={`right-${textKey}`}>
          <h2>{carouselItems[currentSlide].rightTitle}</h2>
          <p>{carouselItems[currentSlide].rightText}</p>
          {carouselItems[currentSlide].rightText2 && <h2>{carouselItems[currentSlide].rightText2}</h2>}
        </div>
        <div className="interiordesign-carousel-dots">
          {carouselItems.map((_, index) => (
            <div
              key={index}
              className={`interiordesign-dot ${index === currentSlide ? 'active' : ''}`}
              onClick={() => goToSlide(index)}
            ></div>
          ))}
        </div>
      </div>
      {/* Nouvelle Section Sous le Carrousel */}
      <div className="interiordesign-section-title">
        Libérez Votre Espace de Rêve : Service de Design d'Intérieur 100% en Ligne
      </div>
      <div className="interiordesign-layout">
        <div className="interiordesign-column">
          <h2 className="interiordesign-h2">Avantages</h2>
          <ul className="interiordesign-ul">
            <li>Disposition optimisée pour correspondre à votre maison et à votre style de vie</li>
            <li>Décoration unique pour correspondre à votre personnalité</li>
            <li>Intervention professionnelle à un prix abordable et préétabli</li>
            <li>Période d'attente de 15 jours sans énergie ou argent superflu dépensé</li>
            <li>Un livret de conception complet, vous permettant d'effectuer les travaux vous-même, à votre rythme ou par les professionnels de votre choix</li>
            <li>Conseils pour améliorer votre bien-être</li>
            <li>Respect de votre budget initial</li>
          </ul>
          <p className="interiordesign-p"><i>Nous fournissons une expertise pour les bureaux, les magasins, les entreprises, les professionnels, les spas, les hôtels, les restaurants, les salons de coiffure, les salons de beauté, les chambres d'hôtes... ainsi que pour les entreprises (banques, écoles...).</i></p>
        </div>
        <div className="interiordesign-column">
          <img src={exampleImage} alt="Exemple" className="interiordesign-column-image"/>
        </div>
        <div className="interiordesign-column">
          <h2 className="interiordesign-h2">Nous incluons :</h2>
          <ul className="interiordesign-ul">
            <li>Dessins professionnels en 2D & rendus en 3D</li>
            <li>Dessins techniques</li>
            <li>Analyse architecturale</li>
            <li>Une liste de meubles et accessoires suggérés</li>
            <li>Conseils en décoration, choix de couleurs et de matériaux</li>
            <li>Et bien plus encore !</li>
          </ul>
          <p className="interiordesign-p">Une opportunité de sauter le pas ! Redécorez, agrandissez, décorez, aménagez une chambre, un salon, un magasin, un bureau ou transformez même une cuisine.</p>
        </div>
      </div>
    </div>
  );
};

export default Interiordesign;
