import React, { useState, useEffect } from 'react';
import './css/intro.css';

// Ensemble d'images original
import img1 from '../assets/introgallery/img1.jpg';
import img2 from '../assets/introgallery/img2.jpg';
import img3 from '../assets/introgallery/img3.jpg';
import img4 from '../assets/introgallery/img4.jpg';
import img5 from '../assets/introgallery/img5.jpg';

// Nouvel ensemble d'images pour la mise en page en miroir
import img6 from '../assets/introgallery/img6.jpg';
import img7 from '../assets/introgallery/img7.jpg';
import img8 from '../assets/introgallery/img8.jpg';
import img9 from '../assets/introgallery/img9.jpg';
import img10 from '../assets/introgallery/img10.jpg';

const Intro = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [img1, img2, img3, img4, img5];
    const reverseImages = [img6, img7, img8, img9, img10];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((currentIndex) => (currentIndex + 1) % images.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <>
            <div className="intro-wrapper">
                <h2 className="concept-to-realisation-title">De la Conception à la Réalisation</h2>
                <div className="intro-container">
                    <div className="intro-text">
                        <h4>Une nouvelle approche combinant l'architecture intérieure, le respect de l'environnement et le bien-être général.</h4>
                        <p>Un service de design d'intérieur en ligne en collaboration avec des experts bénéficiant de plus de 30 ans d'expérience, conçu pour rendre votre maison plus fonctionnelle, plus saine ou simplement plus agréable. Ce nouveau concept vous offre tous les avantages d'une agence qualifiée et d'une équipe à votre service, et réduit considérablement le coût final du service. Tout le processus est réalisé en ligne pour optimiser le temps et réduire les frais de déplacement.</p>
                        <h4>Services rapides et efficaces à portée de main.</h4>
                        <p><i>Architecture, Design intérieur, Relooking Express, Panneaux de suggestions, Home Staging, Feng Shui & Géobiologie, Harmonisation du paysage</i></p>
                    </div>
                    <div className="intro-image">
                        <img
                            src={images[currentImageIndex]}
                            alt={`Galerie ${currentImageIndex + 1}`}
                            key={currentImageIndex}
                        />
                    </div>
                </div>
            </div>

            {/* La mise en page en miroir commence ici */}
            <div className="intro-wrapper">
                <div className="reverse-intro-container">
                    <div className="reverse-intro-text">
                        <h4>Confiez-nous vos projets</h4>
                        <p>Nous sommes là à chaque étape : des plans, à la création d'un design qui reflète votre image et révèle votre intérieur, à la création de modèles de meubles, à la réalisation complète de l'espace et des meubles pour vos cuisines personnalisées, salles de bains... tout est réalisé dans nos ateliers par notre équipe d'experts et nos partenaires.</p>
                        <h4>Design d'intérieur en ligne à 100% ... en ligne</h4>
                        <p><i><ul>
                            <li>Plans professionnels en 2D et rendus en 3D</li>
                            <li>Dessins techniques</li>
                            <li>Analyse architecturale</li>
                            <li>Une liste de meubles et accessoires proposés</li>
                            <li>Conseils en décoration, choix de couleurs et de matériaux</li>
                            <li>Une opportunité de sauter le pas ! Redécorez, agrandissez, décorez, aménagez une chambre, un salon, un magasin, un bureau ou même transformez une cuisine.</li>
                        </ul></i></p>
                    </div>
                    <div className="reverse-intro-image">
                        <img
                            src={reverseImages[currentImageIndex]}
                            alt={`Galerie ${currentImageIndex + 1}`}
                            key={currentImageIndex}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Intro;
