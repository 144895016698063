import React from 'react';
import './css/Carousel.css';
import { useCarousel } from './useCarousel';

const carouselItems = [
  {
    title: "Consultation en Énergie Durable : Solutions Architecturales",
    description: "Conception professionnelle d'espaces extérieurs et socialisation améliorée. Un design impeccable s'intègre harmonieusement avec les éléments naturels. Fabrication de meubles durables et résistants aux intempéries avec une attention méticuleuse aux dimensions..."
  },
  {
    title: "Revêtements Muraux Archetto : Création d'Intérieurs de Chalet Uniques",
    description: "Agencement de l'espace intérieur. Présentation des revêtements muraux ARCHETTO. Construction de chalets avec une atmosphère contemporaine et unique. Création complète de meubles sur mesure..."
  },
  {
    title: "Solutions Vertes : Projets Écologiques & Conseils en Énergie",
    description: "Projets et conseils écologiques. Services pour les particuliers, les entreprises et les communautés. Choisissez une énergie respectueuse de l'environnement."
  },
  {
    title: "Revêtements Muraux Archetto : Création d'Intérieurs de Chalet Uniques",
    description: "Agencement de l'espace intérieur. Présentation des revêtements muraux ARCHETTO. Construction de chalets avec une atmosphère contemporaine et unique. Création complète de meubles sur mesure..."
  },
  {
    title: "VERO REATO",
    description: "Vero Reato sculpte la matière grise pour créer des œuvres minérales organiques en béton nouvelle génération."
  }
];

const Carousel = () => {
  const { currentImg, currentItem, animationPhase, imageAnimationPhase, setImgIndex, images, isMobile } = useCarousel(carouselItems);

  return (
    <div className="carousel">
      <div className={`carousel-text ${isMobile ? 'fadeIn' : animationPhase}`}>
        <h2 className="carousel-title">{currentItem.title}</h2>
        <p>{currentItem.description}</p>
      </div>
      <img 
        src={images[currentImg]}
        alt={`carousel ${currentImg + 1}`}
        className={`carousel-image ${imageAnimationPhase} ${isMobile ? 'mobile-image' : ''}`}
        style={{ width: '100%', height: '100%' }}
      />
      <div className="carousel-dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`carousel-dot ${index === currentImg ? 'active' : ''}`}
            onClick={() => setImgIndex(index)}></span>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
