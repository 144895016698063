import React, { useState } from 'react';
import './css/MainGallery.css';
import Modal from './Modal'; 

import img1 from '../assets/gallery/img1.jpg';
import img2 from '../assets/gallery/img2.jpg';
import img3 from '../assets/gallery/img3.jpg';
import img4 from '../assets/gallery/img4.jpg';
import img5 from '../assets/gallery/img5.jpg';
import img6 from '../assets/gallery/img6.jpg';
import img7 from '../assets/gallery/img7.jpg';
import img8 from '../assets/gallery/img8.jpg';
import img9 from '../assets/gallery/img9.jpg';

const MainGallery = () => {
    const [selectedImage, setSelectedImage] = useState(null);
  
    const images = [
      img1, img2, img3, img4, img5, img6, img7, img8, img9
    ];
  
    const handleImageClick = (image) => {
      setSelectedImage(image);
    };
  
    const handleClose = () => {
      setSelectedImage(null);
    };
  
    return (
        <div className="main-gallery">
        <h2>Our Recent Works</h2>
        <div className="gallery-grid">
          {images.map((image, index) => (
            <div key={index} className="gallery-image" onClick={() => handleImageClick(image)}>
              <img src={image} alt={`Gallery Item ${index + 1}`} />
            </div>
          ))}
        </div>
        {selectedImage && (
          <Modal onClose={handleClose}>
            <img src={selectedImage} alt="Full Size" />
          </Modal>
        )}
      </div>
    );
}
  
export default MainGallery;
