import React from 'react';
import ReactDOM from 'react-dom';
import './css/Team.css'; // Assuming modal styling is included here

const ModalTeam = ({ isOpen, children, onClose }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    (
      <div className="modal-backdrop" onClick={onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <button onClick={onClose} className="modal-close-btn">X</button>
          {children}
        </div>
      </div>
    ),
    document.getElementById('modal-root') // Ensure this ID matches your index.html
  );
};

export default ModalTeam;   