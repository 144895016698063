import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Art from './Art/Art'; // Adjust this import path if necessary
import './frontpage/css/App.css';
import HomePage from './frontpage/HomePage';

import Architecture from './offers/Architecture';
import FengShuiDiagnosis from './offers/fengshuidiagnosis';
import Interiordesign from './offers/Interiordesign';

import LanaTikhonova from './Art/artistpage/lanatikhonova/lanatikhonova';
import NADDAYELLA from './Art/artistpage/NADDAYELLA/naddayella';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/art" element={<Art />} />
          <Route path="/Architecture" element={<Architecture />} />
          <Route path="/fengshuidiagnosis" element={<FengShuiDiagnosis />} />
          <Route path="/Interiordesign" element={<Interiordesign />} />
          <Route path="/lanatikhonova" element={<LanaTikhonova />} />
          <Route path="/NADDAYELLA" element={<NADDAYELLA />} />
        </Routes>
      </Router>
    </div>
  );
}
export default App;