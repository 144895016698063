import React from 'react';
import wallpaper from './assets/wallpaper.png';
import artistImage from './assets/artist.png';
import descriptionImage from './assets/description.png';
import './lanatikhonova.css';

const LanaTikhonova = () => (
    <div className="artist-page">
        <div className="main-image-overlay">
            <img src={wallpaper} alt="Lana Frey Artwork" className="main-image"/>
            <div className="text-overlay">
                <h1 className="main-title">Peintre | Tchéquie</h1>
                <p className="artist-details">Lana Frey</p>
                <p className="artist-details">Née en 1974</p>
            </div>
        </div>
        
        <div className="artist-layout">
            <div className="column">
                <div className="artist-info">
                    <img src={artistImage} alt="Lana Frey" className="artist-bubble"/>
                    <div className="text-info">
                        <p><strong>Lana Frey</strong>, Tchequie</p>
                    </div>
                </div>
                <div className="column-text">
                    <h3>RÉFÉRENCES</h3>
                    <ul>
                        <li>Jeune talent</li>
                        <li>Exposition collective</li>
                        <li>Rayonnement international</li>
                        <li>Travaille sur commande</li>
                    </ul>
                </div>
            </div>
            <div className="column">
                <p>« Mon secret est de mettre librement de la peinture sur une toile, puis d'enlever ce que je n'aime pas et de laisser ce qui est beau. Je répète ensuite ceci jusqu'à ce que j'ai une œuvre d'art. »</p>
                <p>Svetlana Tikhonova est une peintre tchèque passionnée qui a participé à des expositions au Japon, en Pologne, en France et aux États-Unis. Inspirée par l'histoire, les mythes et les légendes, mais principalement axée sur les émotions humaines, elle décrypte les divers mélanges de sentiments que nous éprouvons chaque jour et les transforme en un travail de portrait et de paysage captivant.</p>
            </div>
            <div className="column">
                <img src={descriptionImage} alt="Description Visual" />
            </div>
        </div>
    </div>
);

export default LanaTikhonova;
