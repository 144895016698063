import React, { useState, useEffect, useCallback } from 'react';
import './css/Team.css';
import ModalTeam from './ModalTeam';

import imgC from '../assets/team/imgC.jpg';
import imgL from '../assets/team/imgL.jpg';
import imgT from '../assets/team/imgT.jpg';
import imgE from '../assets/team/imgE.jpg';
import imgG from '../assets/team/imgG.jpg';
import imgJ from '../assets/team/imgJ.jpg';
import imgM from '../assets/team/imgM.jpg';
import imgV from '../assets/team/imgV.jpg';

const teamMembers = [
  { name: 'Elie', job: 'Fondateur', image: imgE, description: 'Biographie d\'Elie...' },
  { name: 'Antoine', job: 'Fondateur', image: imgT, description: 'Biographie d\'Antoine...' },
  { name: 'Melissa', job: 'Architecte intérieur', image: imgM, description: 'Biographie de Melissa...' },
  { name: 'Lior', job: 'Développeur', image: imgL, description: 'Biographie de Lior...' },
  { name: 'Clara', job: 'Architecte', image: imgC, description: 'Biographie de Clara...' },
  { name: 'Mics', job: 'Architecte', image: imgG, description: 'Biographie de Mics...' },
  { name: 'Vito', job: 'Éco-Architecte', image: imgV, description: 'Biographie de Vito...' },
  { name: 'Moune', job: 'Maître Feng Shui', image: imgJ, description: 'Biographie de Moune...' },
];

const Team = () => {
  const [selectedMember, setSelectedMember] = useState(null);
  const [visibleMembers, setVisibleMembers] = useState(teamMembers.slice(0, 4));
  const [nextMembers, setNextMembers] = useState([]);
  const [animationClass, setAnimationClass] = useState('');
  const [isHovering, setIsHovering] = useState(false);

  const prepareNextMembers = useCallback(() => {
    const currentLastIndex = teamMembers.indexOf(visibleMembers[visibleMembers.length - 1]);
    const nextIndex = (currentLastIndex + 1) % teamMembers.length;
    const newNextMembers = teamMembers.slice(nextIndex, nextIndex + 4).length >= 4
      ? teamMembers.slice(nextIndex, nextIndex + 4)
      : [...teamMembers.slice(nextIndex), ...teamMembers.slice(0, 4 - teamMembers.slice(nextIndex).length)];
    setNextMembers(newNextMembers);
  }, [visibleMembers]);

  const cycleMembers = useCallback(() => {
    setVisibleMembers(nextMembers);
    setAnimationClass('slide-in');
    setTimeout(() => setAnimationClass(''), 500);
  }, [nextMembers]);

  useEffect(() => {
    prepareNextMembers();
  }, [visibleMembers, prepareNextMembers]);

  useEffect(() => {
    let timer;
    if (!isHovering) {
      timer = setTimeout(() => {
        setAnimationClass('slide-out');
        setTimeout(() => {
          cycleMembers();
          prepareNextMembers();
        }, 500);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [isHovering, cycleMembers, prepareNextMembers]);

  const handleMemberClick = useCallback((member) => {
    setSelectedMember(member);
  }, []);

  const handleMouseEnter = useCallback(() => setIsHovering(true), []);
  const handleMouseLeave = useCallback(() => setIsHovering(false), []);

  return (
    <>
      <div className="team-title-container">
        <h2 className="team-title">Rencontrez l'Équipe</h2>
      </div>
      <div className={`team-container ${animationClass}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {visibleMembers.map((member, index) => (
          <div key={index} className="team-member" onClick={() => handleMemberClick(member)} role="button" aria-label={`Voir les détails sur ${member.name}`}>
            <img src={member.image} alt={member.name} className="team-image" />
            <div className="team-name">{member.name}</div>
            <div className="team-job">{member.job}</div>
          </div>
        ))}
      </div>
      {selectedMember && (
        <ModalTeam isOpen={!!selectedMember} onClose={() => setSelectedMember(null)}>
          <>
            <img src={selectedMember.image} alt={selectedMember.name} className="modal-image" />
            <h2 className="modal-member-name">{selectedMember.name}</h2>
            <p className="modal-member-description">{selectedMember.description}</p>
          </>
        </ModalTeam>
      )}
    </>
  );
};

export default Team;
