import React from 'react';
import './css/OnlineGallery.css'; // Assurez-vous de créer ce fichier CSS dans le même répertoire

// Mettez à jour le chemin d'importation en fonction de l'endroit où vous placez votre vidéo dans la structure de votre projet
import galleryVideo from '../assets/gallery/3Dgallery.mp4';

const OnlineGallery = () => {
  return (
    <div className="online-gallery-wrapper">
      <h2 className="gallery-main-title">Galerie d'Art en Ligne Archibuild & Bostyl</h2>
      <h2 className="gallery-main-title">L'Exposition Virtuelle</h2> {/* Assurez-vous qu'il est à l'extérieur et au-dessus */}
      <div className="online-gallery-container">
        <div className="gallery-text">
          <h2>Notre Exposition 3D</h2>
          <p>
            Notre Galerie en ligne 3D, où l'innovation rencontre l'art. Cet espace virtuel
            est conçu pour les artistes du monde entier afin de présenter leurs chefs-d'œuvre. Notre galerie
            offre une plateforme unique aux artistes numériques pour exposer leur travail dans un environnement 3D immersif,
            permettant aux amateurs d'art d'explorer et d'apprécier l'art comme jamais auparavant.
            Les artistes sont invités à soumettre leurs œuvres numériques pour être présentées dans notre prochaine
            exposition virtuelle, leur offrant ainsi un nouveau moyen de se connecter avec le public du monde entier.
          </p>
        </div>
        <div className="gallery-video-container">
          <video className="gallery-video" controls>
            <source src={galleryVideo} type="video/mp4" />
            Votre navigateur ne supporte pas la balise vidéo.
          </video>
        </div>
      </div>
    </div>
  );
};

export default OnlineGallery;