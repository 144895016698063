import React from 'react';
import wallpaper from './assets/wallpaper.png';
import artistImage from './assets/artist.png';
import descriptionImage from './assets/description.png';
import '../lanatikhonova/lanatikhonova.css';

const NADDAYELLA = () => (
    <div className="artist-page">
        <div className="main-image-overlay">
            <img src={wallpaper} alt="Lana Frey Artwork" className="main-image"/>
            <div className="text-overlay">
                <h1 className="main-title">Peintre | France</h1>
                <p className="artist-details">NADDAYELLA</p>
                <p className="artist-details">Apporter la même joie que celle éprouvée par l'instant créatif</p>
            </div>
        </div>
        
        <div className="artist-layout">
            <div className="column">
                <div className="artist-info">
                    <img src={artistImage} alt="Lana Frey" className="artist-bubble"/>
                    <div className="text-info">
                        <p><strong>Lana Frey</strong>, Tchequie</p>
                    </div>
                </div>
                <div className="column-text">
                    <h3>RÉFÉRENCES</h3>
                    <ul>
                        <li>Jeune talent</li>
                        <li>Exposition collective</li>
                        <li>Rayonnement international</li>
                        <li>Travaille sur commande</li>
                    </ul>
                </div>
            </div>
            <div className="column">
                <p>« Apporter la même joie que celle éprouvée par l'instant créatif »</p>
                <p>NADDAYELLA est une artiste autodidacte qui vit à Sceaux, au sud de Paris. De formation, ingénieur, elle a longtemps cherché sa voie d'expression libre et c'est grâce à l'univers du ZENTANGLE (Zen et Tangle: enchevêtrer en anglais), méthode de dessin méditatif, qu'elle s'est lancée dans la création artistique en 2020. Elle est d'ailleurs enseignante certifiée de cette méthode venue des Etats-Unis. Elle a d'abord dessiné en noir et blanc avec des feutres à l'encre de Chine, sur petits formats, mais de plus en plus, la couleur s'invite dans ses oeuvres et les formats évoluent également.</p>
            </div>
            <div className="column">
                <img src={descriptionImage} alt="Description Visual" />
            </div>
        </div>
    </div>
);

export default NADDAYELLA;
