import React from 'react';
import './css/RenovationOptions.css'; // Conservez votre importation CSS

const RenovationOptions = () => {
    return (
      <>
        <div className="project-section">
          <div className="project-title"><h2>Votre projet avec l'Agence Bostyl</h2></div>
          
          <div className="theme">
            <div className="theme-title">Planification de votre projet</div>
            <p>Remplissez notre formulaire, et un chef de projet vous guidera et vous conseillera. Vous recevrez une estimation initiale en moins de 48 heures.</p>
          </div>
          <div className="theme">
            <div className="theme-title">Conception</div>
            <p>Nos architectes d'Archibuild vous guident pour optimiser votre intérieur. Plan en 2D, plan en 3D, visite virtuelle... Imaginez votre nouvel agencement.</p>
          </div>
          <div className="theme">
            <div className="theme-title">Travaux de construction</div>
            <p>Commençons ! Il ne reste plus qu'à choisir l'un des entrepreneurs sélectionnés et le type de supervision de chantier.</p>
          </div>
        </div>
      </>
    );
}

export default RenovationOptions;
